import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus, FaEdit, FaTrash, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import {
  fetchCategoriesApi,
  fetchFoodItemsApi,
  addFoodItemApi,
  updateFoodItemApi,
  deleteFoodItemApi,
} from '../../api/foodsApi';

const FoodItems = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [foodItems, setFoodItems] = useState([]);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [selectedFood, setSelectedFood] = useState(null);
  const [formData, setFormData] = useState({
    foodName: '',
    price: '',
    quantity: '',
  });
  const [sortOrder, setSortOrder] = useState('asc'); 
  const [sortCriteria, setSortCriteria] = useState('foodName'); 

  const userId = localStorage.getItem('userId');
  const subdomain = localStorage.getItem('subdomain')?.split('/').pop();

  const fetchCategories = async () => {
    try {
      const categoriesData = await fetchCategoriesApi(subdomain);
      setCategories(categoriesData);
      if (categoriesData.length > 0) {
        setSelectedCategoryId(categoriesData[0].id);
        fetchFoodItems(categoriesData[0].id);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchFoodItems = async (categoryId) => {
    try {
      const foodItemsData = await fetchFoodItemsApi(categoryId);
      setFoodItems(foodItemsData);
      sortFoodItems(foodItemsData);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddFoodItem = async () => {
    if (!formData.foodName || !formData.price || !formData.quantity) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const response = await addFoodItemApi({
        categoryId: selectedCategoryId,
        foodName: formData.foodName,
        price: formData.price,
        quantity: formData.quantity,
        userId: userId,
      });

      toast.success(response.message);
      setFormData({ foodName: '', price: '', quantity: '' });
      setIsAddPopupOpen(false);
      fetchFoodItems(selectedCategoryId);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUpdateFoodItem = async () => {
    if (!formData.foodName || !formData.price || !formData.quantity) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const response = await updateFoodItemApi({
        id: selectedFood.id,
        categoryId: selectedCategoryId,
        foodName: formData.foodName,
        price: formData.price,
        quantity: formData.quantity,
        userId: userId,
      });

      toast.success("Food item updated successfully.");
      setFormData({ foodName: '', price: '', quantity: '' });
      setIsUpdatePopupOpen(false);
      fetchFoodItems(selectedCategoryId);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCategoryChange = (e) => {
    const newCategoryId = e.target.value;
    setSelectedCategoryId(newCategoryId);
    fetchFoodItems(newCategoryId);
  };

  const openDeleteConfirm = (food) => {
    setSelectedFood(food);
    setIsDeleteConfirmOpen(true);
  };

  const handleDeleteFoodItem = async () => {
    try {
      await deleteFoodItemApi(selectedFood.id, userId);
      toast.success("Food item deleted successfully.");
      setIsDeleteConfirmOpen(false);
      fetchFoodItems(selectedCategoryId);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const openAddPopup = () => {
    setFormData({ foodName: '', price: '', quantity: '' });
    setIsAddPopupOpen(true);
  };

  const openUpdatePopup = (food) => {
    setSelectedFood(food);
    setFormData({
      foodName: food.foodName,
      price: food.price,
      quantity: food.quantity,
    });
    setIsUpdatePopupOpen(true);
  };

  const sortFoodItems = (items) => {
    const sortedItems = [...items].sort((a, b) => {
      const aValue = sortCriteria === 'foodName' ? a.foodName : a.price;
      const bValue = sortCriteria === 'foodName' ? b.foodName : b.price;
      if (sortOrder === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    setFoodItems(sortedItems);
  };

  const handleSort = (criteria) => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'; // Toggle sort order
    setSortOrder(newSortOrder);
    setSortCriteria(criteria);
    sortFoodItems(foodItems); // Sort the items again with new criteria
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-700 to-green-400 p-8">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-white">Food Items</h1>
        <button onClick={openAddPopup} className="text-white bg-[#FFFFFF] hover:bg-[#000000] p-2 rounded-full shadow-lg transition-all duration-300">
          <FaPlus size={24} color='#3FCC41' />
        </button>
      </div>

      <div className="mt-6">
        <select
          value={selectedCategoryId}
          onChange={handleCategoryChange}
          className="w-half p-2 mb-4 border border-gray-300 rounded-lg"
        >
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.categoryName}
            </option>
          ))}
        </select>
      </div>

      <div className="flex justify-between items-center mt-4">
        <h2 className="text-xl font-semibold text-white">Food Items</h2>
        <div className="flex items-center space-x-2">
          <span className="text-white">Sort:</span>
          <button
            onClick={handleSort}
            className="text-white flex items-center bg-transparent border-0 focus:outline-none"
          >
            {sortOrder === 'asc' ? (
              <FaArrowUp className="text-white" size={18} />
            ) : (
              <FaArrowDown className="text-white" size={18} />
            )}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
        {foodItems.length === 0 ? (
          <p className="text-white">No food items available in this category.</p>
        ) : (
          foodItems.map((food) => (
            <div key={food.id} className="bg-white p-4 shadow-md rounded-lg flex justify-between items-center">
              <h2 className="text-lg font-semibold">{food.foodName} - ₹{food.price}</h2>
              <div className="flex space-x-2">
                <FaEdit 
                  className="text-blue-500 cursor-pointer" 
                  onClick={() => openUpdatePopup(food)} 
                />
                <FaTrash
                  className="text-red-500 cursor-pointer"
                  onClick={() => openDeleteConfirm(food)}
                />
              </div>
            </div>
          ))
        )}
      </div>

      {/* Add Food Item Popup */}
      {isAddPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-80 gap-4">
          <h2 className="text-2xl font-bold mb-4">Add Food Item</h2>
          <input 
            type="text"
            name="foodName"
            placeholder="Food Name"
            value={formData.foodName}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 mb-2 rounded-lg w-full"
          />
          <input 
            type="number"
            name="price"
            placeholder="Price"
            value={formData.price}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 mb-2 rounded-lg w-full"
          />
          <input 
            type="number"
            name="quantity"
            placeholder="Quantity"
            value={formData.quantity}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 mb-2 rounded-lg w-full"
          />
          <div className="flex justify-between mt-4"> {/* Add mt-4 for margin-top */}
            <button onClick={handleAddFoodItem} className="bg-green-500 text-white p-2 rounded-lg">Add Food Item</button>
            <button onClick={() => setIsAddPopupOpen(false)} className="bg-red-500 text-white p-2 rounded-lg">Cancel</button>
          </div>
        </div>
      </div>
      
      )}

      {/* Update Food Item Popup */}
      {isUpdatePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-2xl font-bold mb-4">Update Food Item</h2>
            <input 
              type="text"
              name="foodName"
              placeholder="Food Name"
              value={formData.foodName}
              onChange={handleInputChange}
              className="border border-gray-300 p-2 mb-2 rounded-lg w-full"
            />
            <input 
              type="number"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={handleInputChange}
              className="border border-gray-300 p-2 mb-2 rounded-lg w-full"
            />
            <input 
              type="number"
              name="quantity"
              placeholder="Quantity"
              value={formData.quantity}
              onChange={handleInputChange}
              className="border border-gray-300 p-2 mb-2 rounded-lg w-full"
            />
            <div className="flex justify-between">
              <button onClick={handleUpdateFoodItem} className="bg-green-500 text-white p-2 rounded-lg">Update</button>
              <button onClick={() => setIsUpdatePopupOpen(false)} className="bg-red-500 text-white p-2 rounded-lg">Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Popup */}
      {isDeleteConfirmOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
            <p>Are you sure you want to delete {selectedFood?.foodName}?</p>
            <div className="flex justify-between mt-4">
              <button onClick={handleDeleteFoodItem} className="bg-red-500 text-white p-2 rounded-lg">Delete</button>
              <button onClick={() => setIsDeleteConfirmOpen(false)} className="bg-gray-500 text-white p-2 rounded-lg">Cancel</button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default FoodItems;
