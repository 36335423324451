import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, useLocation } from 'react-router-dom';
import Header from './components/Admin/Header';
import Footer from './components/Admin/Footer';
import Register from './components/Admin/Auth/Register';
import Login from './components/Admin/Auth/Login';
import Dashboard from './components/Admin/Dashboard/Dashboard';
import Categories from './components/Admin/Categories/Categories';
import FoodItems from './components/Admin/FoodItems/FoodItems';
import ProtectedRoute from './components/Admin/ProtectedRoute';
import Menu from './components/User/Menu';

// Helper function to extract shop name from the URL path or subdomain
function getShopName() {
  const hostname = window.location.hostname;
  const path = window.location.pathname.split('/')[1];
  
  if (hostname === 'localhost') {
    // On localhost, use the path segment as shop name (e.g., http://localhost:3000/shopName)
    return path || null;
  }

  const parts = hostname.split('.');
  if (parts.length > 2 && parts[0] !== 'www') {
    return parts[0];
  }

  return path || null;
}

function AdminPages() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

function App() {
  const shopName = getShopName(); // Get the shop name

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public Routes */}
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Login />} />

          {/* Route for the menu using shop name */}
          {shopName && (
            <Route path="*" element={<Menu shopName={shopName} />} />
          )}

          {/* Admin pages wrapped with Header and Footer */}
          <Route element={<AdminPages />}>
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
            <Route path="/categories" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
            <Route path="/fooditems" element={<ProtectedRoute><FoodItems /></ProtectedRoute>} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
