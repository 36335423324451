import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus, FaEdit, FaTrash, FaArrowUp, FaArrowDown } from 'react-icons/fa'; // Import arrow icons
import { fetchCategories, addCategory, updateCategory, deleteCategory } from '../../api/categoriesApi';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // State to track sort order

  const userId = localStorage.getItem('userId');
  const subdomain = localStorage.getItem('subdomain').split('/').pop();

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categoriesData = await fetchCategories(subdomain);
        setCategories(categoriesData);
      } catch (error) {
        console.error(error);
      }
    };
    loadCategories();
  }, [subdomain]);

  // Sorting logic
  const handleSort = () => {
    const sortedCategories = [...categories].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.categoryName.localeCompare(b.categoryName);
      } else {
        return b.categoryName.localeCompare(a.categoryName);
      }
    });
    setCategories(sortedCategories);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle between ascending and descending
  };

  const handleAddCategory = async () => {
    if (!newCategory) {
      toast.error('Category name cannot be empty.');
      return;
    }

    try {
      await addCategory(newCategory, userId);
      setNewCategory('');
      setIsPopupOpen(false);
      const categoriesData = await fetchCategories(subdomain);
      setCategories(categoriesData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateCategory = async () => {
    if (!editCategoryName) {
      toast.error('Category name cannot be empty.');
      return;
    }

    try {
      await updateCategory(editCategoryId, editCategoryName, userId);
      setEditCategoryId(null);
      setEditCategoryName('');
      setIsPopupOpen(false);
      const categoriesData = await fetchCategories(subdomain);
      setCategories(categoriesData);
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteModal = (categoryId) => {
    setCategoryToDelete(categoryId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCategoryToDelete(null);
  };

  const handleDeleteCategory = async () => {
    try {
      await deleteCategory(categoryToDelete, userId);
      const categoriesData = await fetchCategories(subdomain);
      setCategories(categoriesData);
      setIsDeleteModalOpen(false); 
      toast.success('Category deleted successfully.');
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete category.');
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleEditCategory = (categoryId, categoryName) => {
    setEditCategoryId(categoryId);
    setEditCategoryName(categoryName);
    setIsPopupOpen(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-700 to-green-400 p-8">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-white">Categories</h1>
        
        {/* Sorting button with icon */}
        <div className="flex items-center">
          <button
            onClick={handleSort}
            className="text-white flex items-center bg-transparent border-0 focus:outline-none"
          >
            <span className="mr-2 text-white">Sort:</span>
            {sortOrder === 'asc' ? <FaArrowUp size={18} /> : <FaArrowDown size={18} />}
          </button>
          
          {/* Plus icon for adding a new category */}
          <button
            onClick={togglePopup}
            className="text-white bg-[#FFFFFF] hover:bg-[#000000] p-2 ml-4 rounded-full shadow-lg transition-all duration-300"
          >
            <FaPlus size={24} color='#3FCC41' />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
        {categories.map((category) => (
          <div key={category.id} className="bg-white p-4 shadow-md rounded-lg flex justify-between items-center">
            <h2 className="text-lg font-semibold">{category.categoryName}</h2>
            <div className="flex space-x-2">
              <FaEdit
                className="text-blue-500 cursor-pointer"
                onClick={() => handleEditCategory(category.id, category.categoryName)}
              />
              <FaTrash
                className="text-red-500 cursor-pointer"
                onClick={() => openDeleteModal(category.id)}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Add/Edit Category Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-2xl font-bold mb-4">{editCategoryId ? 'Edit' : 'Add'} Category</h2>
            <input
              type="text"
              placeholder="Enter Category Name"
              value={editCategoryId ? editCategoryName : newCategory}
              onChange={(e) => editCategoryId ? setEditCategoryName(e.target.value) : setNewCategory(e.target.value)}
              className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
            />
            <div className="flex justify-between">
              {editCategoryId ? (
                <button
                  onClick={handleUpdateCategory}
                  className="bg-[#3FCC41] text-white px-4 py-2 rounded-lg hover:bg-[#000000] transition-all duration-300"
                >
                  Update Category
                </button>
              ) : (
                <button
                  onClick={handleAddCategory}
                  className="bg-[#3FCC41] text-white px-4 py-2 rounded-lg hover:bg-[#000000] transition-all duration-300"
                >
                  Add Category
                </button>
              )}
              <button
                onClick={togglePopup}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-all duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-2xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this category?</p>
            <div className="flex justify-between mt-4">
              <button
                onClick={handleDeleteCategory}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-all duration-300"
              >
                Delete
              </button>
              <button
                onClick={closeDeleteModal}
                className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition-all duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Categories;
