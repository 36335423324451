// src/components/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerUser } from '../../api/authApi';

const Register = () => {
  const [shopName, setShopName] = useState('');
  const [shopAddress, setShopAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!shopName || !shopAddress || !email || !phoneNumber || !password || !confirmPassword) {
      toast.error("All fields are required");
      return;
    }
    
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    // Remove spaces from shopName
    const formattedShopName = shopName.replace(/\s+/g, '');

    try {
      const shopDetails = {
        shopName: formattedShopName, // Use formattedShopName instead of shopName
        shopAddress,
        email,
        phoneNumber,
        password
      };
      const response = await registerUser(shopDetails);
      const { message, subdomain, qrCodeUrl } = response.data;
      
      toast.success(
        <div>
          <p>{message}</p>
          <p>Subdomain: <a href={subdomain} target="_blank" rel="noopener noreferrer">{subdomain}</a></p>
          <img src={qrCodeUrl} alt="QR Code" className="w-32 h-32" />
        </div>,
        { autoClose: false }
      );

      setShopName('');
      setShopAddress('');
      setEmail('');
      setPhoneNumber('');
      setPassword('');
      setConfirmPassword('');

      setTimeout(() => {
        navigate('/'); 
      }, 3000);
    } catch (error) {
      toast.error("Registration failed. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-b from-green-700 to-green-400">
      <div className="w-full max-w-md p-8 space-y-8 bg-white shadow-lg rounded-lg">
        <h2 className="text-3xl font-bold text-center text-[#3FCC41] mb-6">Register Your Shop</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <input 
            type="text"
            placeholder="Shop Name"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3FCC41] transition duration-300"
            required
          />
          <input 
            type="text"
            placeholder="Shop Address"
            value={shopAddress}
            onChange={(e) => setShopAddress(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3FCC41] transition duration-300"
            required
          />
          <input 
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3FCC41] transition duration-300"
            required
          />
          <input 
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3FCC41] transition duration-300"
            required
          />
          <input 
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3FCC41] transition duration-300"
            required
          />
          <input 
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3FCC41] transition duration-300"
            required
          />
          <button type="submit" className="w-full py-2 px-4 text-white bg-[#3FCC41] hover:bg-[#000000] rounded-lg shadow-lg font-semibold transition-all duration-300 ease-in-out">
            Register
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
