// src/components/Admin/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children }) => {
  const token = Cookies.get('token'); // Get the token from cookies

  // Check if the user is authenticated
  if (!token) {
    return <Navigate to="/" />; // Redirect to login if not authenticated
  }

  return children; // Render the protected route if authenticated
};

export default ProtectedRoute;
