import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from './Footer';
import TicTacToe from './TicTacToe';
import { IoMdQrScanner } from 'react-icons/io';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const API_URL = process.env.REACT_APP_API_URL;

const Menu = ({ shopName }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [foodItems, setFoodItems] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [isTicTacToeOpen, setIsTicTacToeOpen] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingFoodItems, setLoadingFoodItems] = useState(true);

  // Fetch categories
  useEffect(() => {
    axios
      .get(`${API_URL}/category/${shopName}`)
      .then((response) => {
        setCategories(response.data);
        setLoadingCategories(false);
        if (response.data.length > 0) {
          setSelectedCategory(response.data[0]);
        }
      })
      .catch((error) => console.log(error));
  }, [shopName]);

  // Fetch food items
  useEffect(() => {
    if (selectedCategory) {
      setLoadingFoodItems(true);
      axios
        .get(`${API_URL}/food/foodsByCategory/${selectedCategory.id}`)
        .then((response) => {
          setFoodItems(response.data);
          setLoadingFoodItems(false);
          setQuantities((prevQuantities) => {
            const newQuantities = { ...prevQuantities };
            response.data.forEach((food) => {
              if (!newQuantities[food.id]) {
                newQuantities[food.id] = 0;
              }
            });
            return newQuantities;
          });
        })
        .catch((error) => console.log(error));
    }
  }, [selectedCategory]);

  const toggleWishlist = (foodId) => {
    setWishlist((prevWishlist) =>
      prevWishlist.includes(foodId)
        ? prevWishlist.filter((id) => id !== foodId)
        : [...prevWishlist, foodId]
    );
  };

  const increaseQuantity = (foodId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [foodId]: prevQuantities[foodId] + 1,
    }));
  };

  const decreaseQuantity = (foodId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [foodId]: Math.max(prevQuantities[foodId] - 1, 1),
    }));
  };

  const handleOpenTicTacToe = () => setIsTicTacToeOpen(true);
  const handleCloseTicTacToe = () => setIsTicTacToeOpen(false);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-green-300 to-blue-300">
      <div className="container mx-auto p-4 flex-grow">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          className="flex justify-between items-center mb-6"
        >
          <h1 className="text-5xl font-bold text-white">{shopName}</h1>
          <button
            className="px-4 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-all duration-300"
            onClick={handleOpenTicTacToe}
          >
            Game
          </button>
        </motion.div>

        <div className="flex flex-col md:flex-row gap-6">
          {/* Category List */}
          <div className="w-full md:w-1/4">
            <h2 className="text-xl font-semibold mb-4">Categories</h2>
            <div className="flex overflow-x-auto space-x-4 pb-4">
              {loadingCategories ? (
                [...Array(3)].map((_, i) => (
                  <div
                    key={i}
                    className="w-24 h-24 bg-gray-200 rounded-full animate-pulse"
                  ></div>
                ))
              ) : (
                categories.map((category,i) => (
                  <motion.div
                    key={category.id}
                    className={`flex-shrink-0 w-24 h-24 rounded-full cursor-pointer flex items-center justify-center text-center transition-all duration-300
                    ${selectedCategory?.id === category.id
                        ? 'bg-green-500 text-white shadow-lg'
                        : 'bg-gray-100 hover:bg-blue-500 hover:text-white'
                      }`}
                      style={{ borderRadius: '30%' }}
                    onClick={() => setSelectedCategory(category)}
                    whileHover={{ scale: 1.1 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.1 * i }}
                  >
                    <IoMdQrScanner className='text-8xl text-green-500 absolute' />
                    {category.categoryName}
                  </motion.div>
                ))
              )}
            </div>
          </div>

          {/* Food Items */}
          <div className="w-full md:w-3/4">
            <motion.h2
              className="text-xl font-semibold mb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              {selectedCategory ? (
                <>Menus - <span className="text-white text-2xl">{`{ ${selectedCategory.categoryName} }`}</span></>
              ) : (
                'Select a category'
              )}

            </motion.h2>
            {loadingFoodItems ? (
              [...Array(4)].map((_, i) => (
                <div
                  key={i}
                  className="p-4 bg-white shadow-md rounded-lg flex justify-between items-center animate-pulse mb-4"
                >
                  <div className="w-2/3 h-6 bg-gray-200 rounded mb-2"></div>
                  <div className="w-1/3 h-6 bg-gray-200 rounded mb-2"></div>
                </div>
              ))
            ) : foodItems.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {foodItems.map((food) => (
                  <motion.div
                    key={food.id}
                    className="p-4 bg-white shadow-md rounded-lg flex justify-between items-center"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <motion.div
                      className="text-lg font-bold mb-2"
                      initial={{ scale: 0.8 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.3, ease: "backOut" }}
                    >
                      {food.foodName}
                    </motion.div>
                    <p className="text-lg text-green-600 mb-1">
                      Price: ₹{food.price}
                    </p>
                    {/* <div className="flex items-center mt-2">
                      <button
                        className="px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                        onClick={() => decreaseQuantity(food.id)}
                      >
                        -
                      </button>
                      <span className="mx-2 text-lg">{quantities[food.id]}</span>
                      <button
                        className="px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                        onClick={() => increaseQuantity(food.id)}
                      >
                        +
                      </button>
                    </div> */}
                    <button
                      className="text-2xl"
                      onClick={() => toggleWishlist(food.id)}
                    >
                      <motion.div
                        animate={{ scale: wishlist.includes(food.id) ? 1.3 : 1 }}
                        transition={{ type: 'spring', stiffness: 200 }}
                      >
                        {wishlist.includes(food.id) ? (
                          <FaHeart className="text-red-600" />
                        ) : (
                          <FaRegHeart className="text-gray-400" />
                        )}
                      </motion.div>
                    </button>
                  </motion.div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500">No food items available for this category.</p>
            )}
          </div>
        </div>
      </div>

      {/* TicTacToe Modal */}
      <AnimatePresence>
        {isTicTacToeOpen && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white p-6 rounded-lg shadow-lg"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
            >
              <h2 className="text-2xl mb-4">Tic Tac Toe</h2>
              <TicTacToe />
              <button
                className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-all"
                onClick={handleCloseTicTacToe}
              >
                Close
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Footer */}
      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default Menu;
