import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/Logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    // Clear localStorage
    localStorage.clear();

    // Clear cookies
    document.cookie.split(";").forEach(cookie => {
      const name = cookie.split("=")[0];
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
    });

    // Redirect to login page
    window.location.href = '/';
  };

  return (
    <header className="bg-white shadow">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
        <div className="flex-shrink-0">
            <Link to="/dashboard"> 
              <img src={Logo} alt="Logo" className="h-12 w-auto" />
            </Link>
          </div>

          <nav className="hidden md:flex space-x-4">
            <a 
              href="/dashboard" 
              className={`${currentPath === '/dashboard' ? 'text-gray-700 font-bold' : 'text-gray-700'} hover:text-blue-600`}
              style={{ color: currentPath === '/dashboard' ? '#3FCC41' : 'inherit' }}>
              Dashboard
            </a>
            <a 
              href="/categories" 
              className={`${currentPath === '/categories' ? 'text-gray-700 font-bold' : 'text-gray-700'} hover:text-blue-600`}
              style={{ color: currentPath === '/categories' ? '#3FCC41' : 'inherit' }}>
              Categories
            </a>
            <a 
              href="/fooditems" 
              className={`${currentPath === '/fooditems' ? 'text-gray-700 font-bold' : 'text-gray-700'} hover:text-blue-600`}
              style={{ color: currentPath === '/fooditems' ? '#3FCC41' : 'inherit' }}>
              Food Items
            </a>
            <button onClick={handleLogout} className="text-red-600 hover:text-red-800 ml-4">
              Logout
            </button>
          </nav>

          <div className="md:hidden">
            <button
              type="button"
              className="text-gray-700 hover:text-blue-600"
              aria-expanded={isOpen}
              onClick={toggleMenu}
              aria-label="Toggle Navigation"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="absolute top-0 right-0 mt-2 w-64 bg-white shadow-md p-4 rounded-lg transition-transform transform translate-x-0">
            <nav className="flex flex-col space-y-2">
              <a 
                href="/dashboard" 
                className={`${currentPath === '/dashboard' ? 'text-gray-700 font-bold' : 'text-gray-700'} hover:text-blue-600`}
                style={{ color: currentPath === '/dashboard' ? '#3FCC41' : 'inherit' }}>
                Dashboard
              </a>
              <a 
                href="/categories" 
                className={`${currentPath === '/categories' ? 'text-gray-700 font-bold' : 'text-gray-700'} hover:text-blue-600`}
                style={{ color: currentPath === '/categories' ? '#3FCC41' : 'inherit' }}>
                Categories
              </a>
              <a 
                href="/fooditems" 
                className={`${currentPath === '/fooditems' ? 'text-gray-700 font-bold' : 'text-gray-700'} hover:text-blue-600`}
                style={{ color: currentPath === '/fooditems' ? '#3FCC41' : 'inherit' }}>
                Food Items
              </a>
              <button onClick={handleLogout} className="text-red-600 hover:text-red-800 mt-2">
                Logout
              </button>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
