// foodItemsApi.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Fetch categories by subdomain
export const fetchCategoriesApi = async (subdomain) => {
  try {
    const response = await axios.get(`${API_URL}/category/${subdomain}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch categories.");
  }
};

// Fetch food items by category ID
export const fetchFoodItemsApi = async (categoryId) => {
  try {
    const response = await axios.get(`${API_URL}/food/foodsByCategory/${categoryId}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch food items.");
  }
};

// Add new food item
export const addFoodItemApi = async (foodData) => {
  try {
    const response = await axios.post(`${API_URL}/food/add`, foodData);
    return response.data;
  } catch (error) {
    throw new Error("Failed to add food item.");
  }
};

// Update food item
export const updateFoodItemApi = async (foodData) => {
  try {
    const response = await axios.put(`${API_URL}/food/update`, foodData);
    return response.data;
  } catch (error) {
    throw new Error("Failed to update food item.");
  }
};

// Delete food item
export const deleteFoodItemApi = async (foodId, userId) => {
  try {
    await axios.delete(`${API_URL}/food/delete/${foodId}`, {
      data: { userId }
    });
  } catch (error) {
    throw new Error("Failed to delete food item.");
  }
};
