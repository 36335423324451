import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; // Ensure this is correctly set

// API call to login a user
export const loginUser = async (shopName, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      shopName,
      password,
    });
    return response.data;
  } catch (error) {
    console.error('Login API error:', error); // Log the error for debugging
    throw error; // Re-throw error to handle in component
  }
};

// API call to register a new shop
export const registerUser = async (shopDetails) => {
  return await axios.post(`${API_URL}/auth/register`, shopDetails);
};

// API call to fetch categories based on the shop's subdomain
export const fetchCategories = async (subdomain) => {
  try {
    const response = await axios.get(`${API_URL}/category/${subdomain}`);
    return response.data; 
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch categories');
  }
};
