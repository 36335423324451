import axios from 'axios';
import { toast } from 'react-toastify';


const API_URL = process.env.REACT_APP_API_URL

// Fetch categories by subdomain
export const fetchCategories = async (subdomain) => {
    try {
      const response = await axios.get(`${API_URL}/category/${subdomain}`);
      return response.data;
    } catch (error) {
      toast.error('Failed to fetch categories.');
      throw error;
    }
  };
  
  // Add a new category
  export const addCategory = async (categoryName, userId) => {
    try {
      const response = await axios.post(`${API_URL}/category/add`, {
        categoryName,
        userId,
      });
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      toast.error('Failed to add category.');
      throw error;
    }
  };
  
  // Update an existing category
  export const updateCategory = async (id, newCategoryName, userId) => {
    try {
      const response = await axios.put(`${API_URL}/category/update`, {
        id,
        newCategoryName,
        userId,
      });
      toast.success('Category updated successfully.');
      return response.data;
    } catch (error) {
      toast.error('Failed to update category.');
      throw error;
    }
  };
  
  // Delete a category
  export const deleteCategory = async (categoryId, userId) => {
    try {
      await axios.delete(`${API_URL}/category/delete`, {
        data: { categoryId, userId },
      });
      toast.success('Category deleted successfully.');
    } catch (error) {
      toast.error('Failed to delete category.');
      throw error;
    }
  };