import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { loginUser } from '../../api/authApi'; // Assume loginUser makes the API call to backend

const Login = () => {
  const [shopName, setShopName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Effect to check for token in cookies and redirect to dashboard if it exists
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!shopName || !password) {
      toast.error("All fields are required");
      return;
    }

    // Remove spaces from shopName
    const formattedShopName = shopName.replace(/\s+/g, '');

    try {
      // Call the login API with the formatted shopName
      const response = await loginUser(formattedShopName, password);
      const { userId, message, token, subdomain, qrCodeUrl } = response;

      // Show success message
      toast.success(message);

      // Store token in cookies with 20 days expiration
      Cookies.set('token', token, { expires: 20, path: '/' });

      // Store additional details in local storage
      localStorage.setItem('userId', userId);
      localStorage.setItem('subdomain', subdomain);
      localStorage.setItem('qrCodeUrl', qrCodeUrl);

      // Redirect to dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Login API error:', error); // Log the error for debugging
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message); 
      } else {
        toast.error("Login failed. Please try again.");
      }
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/register');
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-b from-green-700 to-green-400">
      <div className="w-full max-w-md p-8 space-y-8 bg-white shadow-lg rounded-lg">
        <h2 className="text-3xl font-bold text-center text-[#3FCC41] mb-6">Welcome Back</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <input 
            type="text"
            placeholder="Shop Name"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3FCC41] transition duration-300"
            required
          />
          <input 
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3FCC41] transition duration-300"
            required
          />
          <button type="submit" className="w-full py-2 px-4 text-white bg-[#3FCC41] hover:bg-[#000000] rounded-lg shadow-lg font-semibold transition-all duration-300 ease-in-out">
            Login
          </button>
        </form>
        <div className="text-center mt-4">
          <span className="text-gray-600">Don't have an account? </span>
          <button 
            onClick={handleRegisterRedirect} 
            className="text-[#3FCC41] font-semibold hover:underline focus:outline-none"
          >
            Register
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
