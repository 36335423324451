import React, { useState, useEffect } from 'react';
import { FiCopy, FiDownload } from 'react-icons/fi'; // Import Download icon
import { toast } from 'react-toastify';
import { fetchCategories, fetchFoodItems } from '../../api/dashboardApi'; // Import the API service
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const Dashboard = () => {
  const [menuLink, setMenuLink] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState(''); 
  const [shopName, setShopName] = useState('');
  const [categories, setCategories] = useState([]);
  const [foodItems, setFoodItems] = useState([]); // State for food items
  const [loading, setLoading] = useState(true); // State for loading
  const [isCopied, setIsCopied] = useState(false); // Track copy status
  const navigate = useNavigate(); // Initialize the navigation

  useEffect(() => {
    const storedMenuLink = localStorage.getItem('subdomain') || `https://test.wat2eat.shop`;
    const storedQrCodeUrl = localStorage.getItem('qrCodeUrl') || 'https://via.placeholder.com/150';
    const subdomain = storedMenuLink.split('/').pop();
    const userId = localStorage.getItem('userId'); // Fetch userId from local storage
    setShopName(subdomain);
    setMenuLink(storedMenuLink);
    setQrCodeUrl(storedQrCodeUrl);

    // Fetch categories and food items from API using the service
    const fetchData = async () => {
      try {
        const categoriesData = await fetchCategories(subdomain);
        setCategories(categoriesData); // Set the categories in state

        const foodItemsData = await fetchFoodItems(userId); // Fetch food items using userId
        setFoodItems(foodItemsData); // Set the food items in state
      } catch (error) {
        toast.error('Error fetching data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(menuLink);
    setIsCopied(true); // Set copied state to true
    toast.success('Menu Link Copied!');
    setTimeout(() => {
      setIsCopied(false); // Reset after 2 seconds
    }, 2000);
  };

  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrCodeUrl;
    link.download = `${shopName}_QRCode.png`; // Set download name for the QR code
    link.click();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-700 to-green-400 p-8">
      <h2 className="text-5xl font-bold text-white mb-2 text-center">{shopName}</h2>
      <br/>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white rounded-lg shadow-lg p-6 text-center transform transition-transform hover:scale-105">
            <h3 className="text-2xl font-bold text-gray-700 mb-2">No. of Categories</h3>
            <p className="text-5xl font-extrabold text-green-600">{loading ? '...' : categories.length}</p>
            <button 
              onClick={() => navigate('/categories')} // Navigate to categories management page
              className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300"
            >
              Manage Categories
            </button>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6 text-center transform transition-transform hover:scale-105">
            <h3 className="text-2xl font-bold text-gray-700 mb-2">No. of Food Items</h3>
            <p className="text-5xl font-extrabold text-green-600">{loading ? '...' : foodItems.length}</p>
            <button 
              onClick={() => navigate('/fooditems')} // Navigate to food items management page
              className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300"
            >
              Manage Food Items
            </button>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6 text-center transform transition-transform hover:scale-105">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">Menu Link & QR Code</h3>
            <div className="flex items-center justify-center mb-4">
              <a href={menuLink} target="_blank" rel="noopener noreferrer" className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300">
                Visit Menu
              </a>
              <button onClick={copyToClipboard} className="ml-2 bg-gray-100 p-2 rounded-full hover:bg-gray-200 transition duration-300">
                <FiCopy className="text-green-500 text-xl" />
              </button>
              {isCopied && <span className="ml-5 text-sm text-green-600">Copied</span>} {/* Show "Copied" text */}
            </div>
            <div className="flex items-center justify-center">
              <img src={qrCodeUrl} alt="QR Code" className="w-32 h-32 rounded-md shadow-md" />
              <button onClick={downloadQRCode} className="ml-2 bg-gray-100 p-2 rounded-full hover:bg-gray-200 transition duration-300">
                <FiDownload className="text-green-500 text-xl" /> 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
