import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// API call to fetch categories based on the shop's subdomain
export const fetchCategories = async (subdomain) => {
  try {
    const response = await axios.get(`${API_URL}/category/${subdomain}`);
    return response.data; 
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch categories');
  }
};

// API call to fetch food items based on the userId
export const fetchFoodItems = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/food/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch food items');
  }
};
