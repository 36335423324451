import React, { useState, useEffect } from 'react';

const TicTacToe = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const [mode, setMode] = useState(null);
  const [gameStatus, setGameStatus] = useState('');
  const winner = calculateWinner(board);

  useEffect(() => {
    if (winner) {
      setGameStatus(`${winner} wins!`);
    } else if (board.every((square) => square)) {
      setGameStatus('Match Draw!');
    } else {
      setGameStatus(`Next Player: ${isXNext ? 'X' : 'O'}`);
    }
  }, [winner, board, isXNext]);

  const handleClick = (index) => {
    if (board[index] || winner) return;

    const newBoard = board.slice();
    newBoard[index] = isXNext ? 'X' : 'O';
    setBoard(newBoard);

    if (mode === 'double') {
      setIsXNext(!isXNext);
    } else if (mode === 'single') {
      setIsXNext(false); // Set to AI after player's move
    }
  };

  const handleAI = () => {
    const emptySquares = board.map((value, index) => (value === null ? index : null)).filter((val) => val !== null);
    
    // AI makes a random move
    if (emptySquares.length > 0) {
      const randomIndex = emptySquares[Math.floor(Math.random() * emptySquares.length)];
      const newBoard = board.slice();
      newBoard[randomIndex] = 'O'; // AI plays 'O'
      setBoard(newBoard);
      setIsXNext(true); // Toggle back to player X
    }
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
    setGameStatus('');
  };

  const renderSquare = (index) => (
    <button
      className="w-16 h-16 text-3xl font-bold border border-gray-400"
      onClick={() => handleClick(index)}
    >
      {board[index]}
    </button>
  );

  const renderGameBoard = () => (
    <div>
      <h1 className="text-3xl mb-4">{gameStatus}</h1>
      <div className="grid grid-cols-3 gap-2">
        {Array.from({ length: 9 }, (_, index) => renderSquare(index))}
      </div>
      <button
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
        onClick={resetGame}
      >
        Restart Game
      </button>
    </div>
  );

  const renderModeSelection = () => (
    <div className="flex flex-col items-center">
      <h1 className="text-3xl mb-4">Select Game Mode</h1>
      <button
        className="mt-2 px-4 py-2 bg-green-500 text-white rounded"
        onClick={() => {
          setMode('single');
          resetGame();
        }}
      >
        Single Player
      </button>
      <button
        className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
        onClick={() => {
          setMode('double');
          resetGame();
        }}
      >
        Two Players
      </button>
    </div>
  );

  // Automatically trigger AI move after the player's turn in single-player mode
  useEffect(() => {
    if (mode === 'single' && !isXNext && !winner) {
      const timer = setTimeout(() => {
        handleAI();
      }, 500); // Delay AI move for better user experience
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [isXNext, mode, winner, board]);

  return (
    <div className="flex flex-col items-center mt-10">
      {mode ? renderGameBoard() : renderModeSelection()}
    </div>
  );
};

const calculateWinner = (squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
};

export default TicTacToe;
