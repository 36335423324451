import React from 'react';
import Logo from '../../assets/Logo.png';

const Footer = () => {
  return (
    <footer className="bg-white text-white py-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        {/* Left Side: Logo */}
        <div className="flex items-center mb-4 md:mb-0">
          <img src={Logo} alt="Logo" className="h-10" /> {/* Adjust path and size as needed */}
        </div>

        {/* Right Side: Product Info and Contact */}
        <div className="text-center text-black md:text-right">
          <p className="text-lg font-semibold">About Our Product</p>
          <p className="text-sm">We offer a seamless experience to help you manage your food choices.</p>
          <p className="text-sm">Contact us:</p>
          <p className="text-sm">
            Email: <a href="mailto:support@wat2eat.shop" className="text-blue-500">support@wat2eat.shop</a>
          </p>
          <p className="text-sm">
            Phone: <a href="tel:+919952379123" className="text-blue-500">+91 81481 85485</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
